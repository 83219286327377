import * as _datatables2 from "datatables.net";

var _datatables = "default" in _datatables2 ? _datatables2.default : _datatables2;

var exports = {};

/*! DataTables styling integration
 * ©2018 SpryMedia Ltd - datatables.net/license
 */
(function (factory) {
  if (true) {
    // CommonJS
    exports = function (root, $) {
      if (!root) {
        root = window;
      }

      if (!$ || !$.fn.dataTable) {
        // Require DataTables, which attaches to jQuery, including
        // jQuery if needed and have a $ property so we can access the
        // jQuery object that is used
        $ = _datatables(root, $).$;
      }

      return factory($, root, root.document);
    };
  } else {}
})(function ($, window, document, undefined) {
  return $.fn.dataTable;
});

export default exports;